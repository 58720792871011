var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"container",attrs:{"id":"zffw"}},[_c('div',{staticClass:"box box_padding"},[_c('div',{staticClass:"title"},[_vm._v("支付服务")]),_c('div',{staticClass:"card_list"},[_c('div',{staticClass:"card_item",on:{"click":_vm.toPosDetail}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toJhmDetail}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toSfkDetail}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toDsdfDetail}},[_vm._m(7),_vm._m(8)])])])]),_c('div',{staticClass:"container",attrs:{"id":"qyjy"}},[_c('div',{staticClass:"box box_padding"},[_c('div',{staticClass:"title"},[_vm._v("企业经营")]),_c('div',{staticClass:"card_list"},[_c('div',{staticClass:"card_item",on:{"click":_vm.toMhDetail}},[_vm._m(9),_vm._m(10)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toMcDetail}},[_vm._m(11),_vm._m(12)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toSyjDetail}},[_vm._m(13),_vm._m(14)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toDdxcxDetail}},[_vm._m(15),_vm._m(16)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toYsDetail}},[_vm._m(17),_vm._m(18)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toGsxxyzDetail}},[_vm._m(19),_vm._m(20)]),_c('i'),_c('i'),_c('i')])])]),_c('div',{staticClass:"container",attrs:{"id":"cjcp"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v("场景服务")]),_c('div',{staticClass:"card_list"},[_c('div',{staticClass:"card_item",on:{"click":_vm.toQytcDetail}},[_vm._m(21),_vm._m(22)]),_c('div',{staticClass:"card_item",on:{"click":_vm.toK12Detail}},[_vm._m(23),_vm._m(24)]),_c('i'),_c('i'),_c('i')])])]),_vm._m(25)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/banner.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/zffw1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("POS收款")]),_c('div',{staticClass:"card_content"},[_vm._v("可提供刷卡、插卡、挥卡、扫码服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/zffw2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("聚合码收款")]),_c('div',{staticClass:"card_content"},[_vm._v("可摆放、可张贴")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/zffw3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("批量收款/付款")]),_c('div',{staticClass:"card_content"},[_vm._v("可提供刷卡、插卡、挥卡、扫码服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/zffw4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("代收/代付")]),_c('div',{staticClass:"card_content"},[_vm._v("可提供刷卡、插卡、挥卡、扫码服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("营销推广")]),_c('div',{staticClass:"card_content"},[_vm._v("多渠道多场景、客户一号触达、优化销售场景、提高商机转化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("凭证归集")]),_c('div',{staticClass:"card_content"},[_vm._v("标准化凭证一键归集，让您记账更便捷")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("收银机")]),_c('div',{staticClass:"card_content"},[_vm._v("满足各类餐饮、零售、甜品店铺实际销售场景使用需求，支持会员、外卖等场外销售建设")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("点单小程序")]),_c('div',{staticClass:"card_content"},[_vm._v("满足餐饮、零售、甜品店铺等具有自助消费场景的需求，客户自助点单，省时省力，支持商家私域流量池搭建")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("三/四要素验证")]),_c('div',{staticClass:"card_content"},[_vm._v("官方权威核查，实时校验，登录场景身份验证小帮手")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("工商信息验证")]),_c('div',{staticClass:"card_content"},[_vm._v("企业工商信息实时查询，联动官方机构，可应用：电商平台的店铺入驻，小微企业贷款、商户入网信息审核")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy2-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("企业团餐")]),_c('div',{staticClass:"card_content"},[_vm._v("满足企业食堂等使用场景支持，刷脸支付、餐补政策、员工考勤等多种服务能力")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_img"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/qyjy2-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_main"},[_c('div',{staticClass:"card_title"},[_vm._v("K12")]),_c('div',{staticClass:"card_content"},[_vm._v("面向校园、教育行业服务，家长实时了解学生在校消费动向，学生免去带现金进校园问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"hlht"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v("互联互通")]),_c('div',{staticClass:"ht_list"},[_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht1.png"),"alt":""}})]),_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht2.png"),"alt":""}})]),_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht3.png"),"alt":""}})]),_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht4.png"),"alt":""}})]),_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht5.png"),"alt":""}})]),_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht6.png"),"alt":""}})]),_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht7.png"),"alt":""}})]),_c('div',{staticClass:"ht_item"},[_c('img',{attrs:{"src":require("../../assets/img/digitalService/ht8.png"),"alt":""}})]),_c('i'),_c('i'),_c('i')])])])
}]

export { render, staticRenderFns }